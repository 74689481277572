
.form {
  margin: 10px 0;
}
.checkbox {
  align-self: flex-start;
}
.grey-color {
  color: #696969;
  line-height: 20px;
}
.red-color {
  color: red;
}
.grey-color-light {
  color: #a8a8a8;
  font-size: 10px;
  line-height: 8px;
}
.error-message {
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
  color: #ff5252;
}
.error-color {
  color: #ff5252;
}

.zip-field {
  width: 100%;
  min-height: 40px;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-property: all;
  transition-property: height, min-height;
  width: 100%;
  margin-bottom: 4px;
  background: transparent;
  padding: 0 12px;
  cursor: text;
  align-items: stretch;
  caret-color: #ff5252 !important;
  font-size: 16px;
  letter-spacing: normal;
  text-align: left;
  tab-size: 4;
  word-break: normal;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: break-word;
  white-space: normal;
  color: #212121;
  border: 1px solid #9e9e9e !important;
  border-radius: 3px !important;
}

.zip-field--error {
  border: 2px solid #ff5252;
  color: #ff5252;
}
.zip-field--error::-moz-placeholder {
  color: #ff5252;
  opacity: 1;
}
.zip-field--error::placeholder {
  opacity: 1;
  color: #ff5252;
}
