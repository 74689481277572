
.container {
  cursor: pointer;
  z-index: 1000;
}
.read {
  background: #f5f6f6;
}
.card {
  transition: all 0.3s;
}
.card:hover {
  background: #eee;
}
.time {
  color: #666666;
}
.rounded {
  border-radius: 5px !important;
}
.custom-tooltip {
  pointer-events: auto;
}
