
.box {
  transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);
}
.chat-footer {
  margin-top: 40px;

  .image-preview-container {
    display: flex;
    align-items: center;
    margin-right: 0px;
  }
  .preview-image {
    max-width: 100px;
    border-radius: 5px;
    margin-right: 5px;
  }
}
.bubble {
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 90%;
}
.blue--bubble {
  background-color: #1982fc;
  color: white;
}
.grey--bubble {
  background-color: lightgrey;
  color: black;
}
.orange--bubble {
  background-color: orange;
  color: white;
}
.chat-image {
  max-width: 200px;
  border-radius: 10px;
  margin-top: 5px;
}

.popup-image {
  width: 100%;
  height: auto;
}
