
  .flex-table {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0.75em 0;
    height: 45px;
    width: 100%;
  }

  .flex-row {
    width: 20%;
  }

  .flex-row input,
  .flex-row select {
    margin: 1px;
    padding: 3px 5px;
    width: 110px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    vertical-align: middle;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
  }
  .flex-row input:focus,
  .flex-row select:focus {
    border: none;
  }

  .flex-row.day {
    width: 130px;
  }

  .flex-row.toggle {
    width: 130px;
  }

  .flex-row.hours {
    width: 110px;
  }

  .flex-row.dash {
    margin: 0 7px;
    width: 4px;
  }

  .row-container {
    flex-direction: column;
  }

  .row {
    flex-direction: row;
  }

  .remove {
    display: flex;
    justify-content: center;
    width: 50px;
  }

  label.vue-js-switch {
    margin-bottom: 0;
  }

  button.add-hours,
  button.font-awesome-button {
    height: 30px;
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    padding: 0;
    cursor: pointer;
  }

  button.add-hours:focus,
  button.font-awesome-button:focus {
    outline: none;
  }

  button.font-awesome-button {
    width: 30px;
    font-size: 24px;
  }

  button.add-hours {
    font-size: 14px;
    font-weight: bold;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter,
.fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .time-errors {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #e3342f;
    list-style: none;
  }

  .time-errors li {
    margin-bottom: 6px;
  }

  @media only screen and (max-width: 600px) {
    .flex-row.day {
      width: 100%;

      text-align: center;
    }
    .flex-row.day div {
      margin-bottom: 10px;
    }
    .flex-table {
      display: flex;
      flex-flow: row wrap;
      height: 100%;
    }
    .flex-row.add {
      width: 100%;
    }

    .flex-row.hours,
    .flex-row.toggle {
      width: 20%;
    }
    .flex-row.toggle {
      margin-right: 50px;
    }
    .flex-row input {
      width: 100%;
    }
  }
