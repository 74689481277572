
.edit-photo {
  position: absolute;
  top: 8px;
  right: 10px;
}
.btn {
  text-transform: unset !important;
}
.wrapper {
  // background-color: #444444;
  border-radius: 15px;
  overflow: hidden;
  border: 2px solid #d5d5d58a;
}

.text-blue {
  color: #2c58f9;
}
.text-red {
  color: red;
}
.call-now {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.map {
  margin-bottom: 30px;
}
.top {
  position: relative;
  margin-bottom: 60px;
}
.card-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 80%;
  padding: 20px 30px;
  border-radius: 50px !important;
}
.main-title {
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: center;
  justify-content: center;
  word-break: break-word;
}
@media only screen and (max-width: 600px) {
  .main-title {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center;
  }
  .card-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 92%;
    padding: 15px;
  }
  .mobile-row {
    display: flex;
    flex-wrap: nowrap;
  }
  .mobile-flex-50 {
    flex: 45%;
  }
  // .v-application .elevation-3 {
  //   box-shadow: none !important;
  // }
  .v-application .ma-1 {
    margin: 0 !important;
  }
}
