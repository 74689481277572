
.custom-btn {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #cecece;
  height: 100px;
  width: 100%;
  transition: all 0.28s;
  padding-left: 5px;
  padding-right: 5px;

  &:hover {
    background-color: #a3a3a3;
  }

  &__main-txt {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  &__caption-txt {
    color: #828282;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}

.custom-btn-selected {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: #2b2b2b;
  padding-left: 5px;
  padding-right: 5px;

  &:hover {
    background-color: #5e5e5e;
  }

  &__main-txt {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  &__caption-txt {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}
