
.bold-tab {
  color: #fff;
  font-weight: 600;
}
.custom-tooltip {
  pointer-events: auto;
}
.tooltip-btn {
  background: #f5f5f5 !important;
  color: #525252;
  margin-left: 2px;
}
