.breakword {
  word-break: break-word;
}

/* not working because of vuetify (imported in index.html) */
.compact-font {
  font-family: 'SF Compact Display' !important;
}

.btn-resetcase {
  text-transform: unset !important;
}
