
.item {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding: 20px 35px;
  text-align: center;
}
.item span {
  text-align: center;
  line-height: 50px;
  font-size: 50px;
  font-weight: 700;
}
