
.review-box {
  display: flex;
  background-color: lightgrey;
  border-radius: 20px;

  .review-custom-card {
    width: 500px;
    padding: 14px;
    padding-bottom: 30px;
  }
}

.fb-review {
  display: flex;
  justify-content: center;
  width: 95%;
}

.review-warning {
  color: red;
}

@media only screen and (max-width: 600px) {
  .review-box {
    .review-custom-card {
      width: 95%;
      padding: 10px;
      padding-bottom: 10px;
    }
  }
}
