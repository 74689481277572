
.system-img {
  cursor: pointer;
}
.btn-caption {
  color: #7d7d7d;
  font-size: 10px;
}
.breakword {
  word-break: break-word;
}
