
.review-custom-card {
  padding: 12px;
  margin-top: 0px;
  border-radius: 2px !important;
  padding-bottom: 24px;
  width: 100%;
  height: 100%;
}
.v-avatar,
.avatar {
  width: 100% !important;
  height: 100% !important;
  min-width: 40px !important;
}
.name {
  letter-spacing: 0.15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Helvetica, Arial, sans-serif;
  color: #1c1e21;
}
.date {
  font-weight: 600;
  color: #90949c;
  font-size: 12px;
  line-height: 14px;
  font-family: Helvetica, Arial, sans-serif;
  word-wrap: break-word;
}
.review-custom-content {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.32px;
  word-wrap: break-word;
  font-family: Helvetica, Arial, sans-serif;
  color: #1c1e21;
  direction: ltr;
}

.logo-box {
  flex: 0 0 40px;
  height: 40px;
}
// .v-avatar {
//   height: auto;
//   width: 100%;
//   min-width: 100%;
// }
.logo {
  height: 24px;
  width: 40px;
}
.link-btn {
  color: #385898;

  &:hover {
    text-decoration: underline;
  }
}
