
.consumer-footer {
  height: 200px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;

    .nav-item {
      list-style: none;
      margin-top: 5px;

      .link {
        text-align: left;
        text-decoration: none;
        display: inline-block;
        color: #454444;

        &:link {
          color: #454444;
        }
      }
    }
  }
  .copyright-box {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .copyright-content {
      display: inline-block;

      a {
        text-decoration: none;
        color: #454444;
      }
    }
  }
}
