
.call-now {
  color: blue;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gift {
  height: 80%;
  width: auto;
}
.code-toolbar {
  background: #444 !important;
}
.code-box {
  background: #bdbdbd;
  padding: 5px;
  border-radius: 5px;
}
