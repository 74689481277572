
.fixed-height {
  height: 42px;
  display: flex;
  align-items: center;
}

.salon-tabs {
  bottom: 0;
  max-width: 100%;
  position: sticky;
  z-index: 100;
}
