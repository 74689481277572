
.highlights-wrapper {
  max-width: 100%;
}
.v-icon {
  border: 3px solid lightgrey;
  padding: 21px;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .v-icon {
    border: 3px solid lightgrey;
    padding: 11px;
    border-radius: 50%;
  }
  .highlights-wrapper {
    max-width: 330px;
  }
}
