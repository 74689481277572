
.tabs {
  bottom: 0;
  position: sticky;
  z-index: 100;
}
.bottom-menu {
  margin-top: auto;
  background-color: #fafafa;
}
