
.link-btn {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 15px;
  padding: 6px 12px;
  border: 2px solid #385898;
  color: #385898;

  &:hover {
    text-decoration: underline;
  }
}
.breakword {
  word-break: break-word;
}
