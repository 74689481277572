
.box {
  background: #fff;
}
.heading {
  color: black;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}
